var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row gutter-b"},[_c('div',{staticClass:"col-xxl-3 col-md-3"},[_c('spamtweetspercentage',{ref:"spamtweetspercentage"})],1),_c('div',{staticClass:"col-xxl-3 col-md-3"},[_c('totalfrequentspammers',{attrs:{"pre_data":{
          isLoading: _vm.isFrequentSpammerDataLoading,
          totalFrequentSpammersCount: _vm.totalFrequentSpammersCount
        }}})],1),_c('div',{staticClass:"col-xxl-3 col-md-3"},[_c('spamtweetsperminute',{ref:"spamtweetsperminute"})],1),_c('div',{staticClass:"col-xxl-3 col-md-3"},[_c('totaltweets',{ref:"totaltweets"})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-6 col-md-6"},[_c('spamtweetstrends',{attrs:{"pre_data":{
          isLoading: _vm.isTotalTweetCountPast7DaysLoading,
          series: _vm.spamTweetsTrendsDataSeries,
          options: _vm.spamTweetsTrendsDataOptions
        }}})],1),_c('div',{staticClass:"col-xxl-6 col-md-6"},[_c('spamvsall',{ref:"spamvsall"})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-4 col-md-4"},[_c('frequentspammer',{attrs:{"pre_data":{
          isLoading: _vm.isFrequentSpammerDataLoading,
          frequentSpammersArray: _vm.frequentSpammersArray
        }}})],1),_c('div',{staticClass:"col-xxl-4 col-md-4"},[_c('tweetsprogress',{attrs:{"pre_data":{
          isLoading: _vm.isTotalTweetCountPast7DaysLoading,
          series: _vm.tweetProgressDataSeries,
          options: _vm.tweetProgressDataOptions,
          spamTweetCount: _vm.tweetProgressSpamTweetCount,
          totalTweetCount: _vm.tweetProgressTotalTweetsCount
        }}})],1),_c('div',{staticClass:"col-xxl-4 col-md-4"},[_c('clientswidget',{ref:"clientswidget"})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-xxl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12"},[_c('ChartMap',{ref:"chartmap"})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12"},[_c('latesttweetswidget')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }